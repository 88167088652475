<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <label class="header_name">Add Real Time Feedback</label>
      <label class="reporteename"> </label>
    </div>

    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            (change)="onChangeAll(realTimeFeedback, $event.target.checked)"
          />&nbsp;&nbsp;<span class="weigted-font">Employee</span>
        </th>
        <th><span class="normal-text">Feedback Title</span></th>
        <th><span class="normal-text">Score</span></th>
        <th><span class="normal-text">Role</span></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let feedback of realTimeFeedback">
        <td class="checkbox-field">
          <input
            type="checkbox"
            (change)="onChange(feedback, $event.target.checked)"
            [checked]="allSelected"
          />&nbsp;&nbsp;
          <span class="weigted-font"
            >(ID
            {{ this.userIdDetailsMapping[feedback.createdBy].employeeId }}){{
              this.userIdDetailsMapping[feedback.createdBy].firstName
            }}</span
          >
        </td>
        <td>
          <span class="normal-text">{{ feedback.title }}</span>
        </td>
        <td>
          <div class="value">{{ feedback.feedbackFrom[0].score }}</div>
        </td>
        <td>
          <div class="value">
            <span class="blue-color"
              ><span class="normal-text">{{
                feedback.feedbackFrom[0].feedbackRole
                  | underScoreToSpace
                  | titlecase
              }}</span></span
            >
          </div>
        </td>
        <td>
          <label (click)="view(feedback.feedbackFrom[0])"
            ><span class="blue-color">View</span></label
          >
        </td>
      </tr>
    </tbody>
  </table>
  <div class="col-12 div-add">
    <button (click)="onSubmit()" class="btn-add">Add</button>
  </div>
</div>
