import { Component, Input, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  hasRoleBased: boolean=false;
  hasCmRights: boolean = false;

  constructor(
    private userDetailsService: UserDetailsService,
    private talentservice: TalentService,
    private projectservice: ProjectService
  ) {}

  ngOnInit(): void {
    // this.getProject();
    //If logged in user is Admin, then set hasRoleBased to true
    // this.hasRoleBased = this.userDetailsService.hasRole(['ROLE_ADMIN']);
    // this.hasCmRights = this.userDetailsService.hasRole(['ROLE_CAREER_MANAGER']);
    this.verifyAuthorities();
  }
  verifyAuthorities()
  {
    this.hasRoleBased = this.userDetailsService.hasRole(['ROLE_ADMIN']);
    this.hasCmRights = this.userDetailsService.hasRole(['ROLE_CAREER_MANAGER']);
  }
  // getProject() {
  //   this.talentservice
  //     .getTalentByUserIdSubscribe(this.userDetailsService.getUserDetails().id)
  //     .subscribe((res) => {
  //       for (let r of res.body) {
  //         this.projectservice.getProjectById(r.projectId).subscribe((resp) => {
  //           this.projectList.push(resp.body);
  //         });
  //       }
  //     });
  // }
}
