<form [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <div class="main-div">
    <div class="header">
      <p class="add-new-role">Add Role</p>
      <label class="close-btn" (click)="closeDialog()">Close</label>
    </div>
    <div class="form-group">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Employee</mat-label>
          <mat-select
            formControlName="feedbackFrom"
            (selectionChange)="getUserID($event.value, 0)"
          >
            <input
              class="myInput"
              #myInput
              matInput
              focused="'true'"
              type="text"
              (keyup)="search($event.target.value, 0)"
              (focusout)="focusOutEvent(0)"
              autocomplete="off"
              placeholder="search name"
            />
            <mat-option
              *ngFor="let user of this.displaySelectedArea"
              [value]="user"
            >
              {{ user }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="role">
        <mat-form-field appearance="outline">
          <mat-label>Role</mat-label>
          <mat-select
            formControlName="feedbackRole"
            (selectionChange)="selectedRole($event.value, 0)"
          >
            <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
              {{ role | underScoreToSpace | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError">Required</mat-error>
        </mat-form-field>
      </div>
      <div class="role">
        <mat-form-field appearance="outline">
          <mat-label>Weightage</mat-label>
          <input
            formControlName="weightage"
            (ngModelChange)="onWeightagetyped($event)"
            matInput
            autocomplete="off"
          />
          <mat-error
            *ngIf="this.formGroup.get('weightage').errors?.InvalidWeightage"
          >
            {{ emailError ? emailError : "Required" }}
          </mat-error>
          <mat-error *ngIf="this.formGroup.get('weightage').errors?.required">
            {{ emailError ? emailError : "Required" }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <button type="submit" class="form-group-btn">
      <span class="centered-label-text">Add</span>
    </button>
  </div>
</form>
