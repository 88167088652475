<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">
        {{
          this.notes.switchedTab == "PROBATION"
            ? "Probation Feedback Notes"
            : "Feedback Notes"
        }}
      </h1>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
      <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <hr />
  <form [formGroup]="feedbackNotes">
    <textarea
      formControlName="note"
      class="notes-box mt-3"
      placeholder="Notes for Feedback"
    ></textarea>
    <div class="save-btn-div">
      <button mat-raised-button class="save-btn" (click)="save()">Save</button>
    </div>
  </form>
</div>
