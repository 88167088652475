import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FeedbackType } from 'src/app/services/user/user-enum';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialog,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { FeedbackSummeryComponent } from '../profile-employee/public-profile/feedback-summery/feedback-summery.component';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';

@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonFormComponent implements OnInit {
  images = [
    'assets/images/BA-logo.png',
    'assets/images/BA-logo.png',
    'assets/images/BA-logo.png',
  ];
  inc;
  @ViewChild('formAlignment') elementView: ElementRef;
  @ViewChild(DynamicFormComponent, { read: ElementRef })
  elementView1: ElementRef;
  showScrollText = false;
  flag = [];
  answers = new Map<any, any>();
  jsonFormData: any;
  jsonObjectData: any;
  temp: string;
  favoriteSeason: string;
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];

  @HostListener('scroll', ['$event'])
  scrollMe(event) {}

  public myForm: FormGroup = this.fb.group({});
  len: any;
  isDisabled = true;
  isProbation = false;
  isReverseProbation = false;
  arrayOfAnswer = [];
  totalScore = 0;
  numberOfQuestion = 0;
  isSavedDraft = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    public feedbackService: FeedbackService,
    private toast: ToastrService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private refreshService: RefreshService,

    @Inject(MAT_DIALOG_DATA) public dataActual: any
  ) {
    this.jsonObjectData = JSON.parse(this.dataActual[0]);

    this.inc = 0;
    this.len = this.jsonObjectData.length;
    let i = 0;
    if (this.dataActual[1] != false && this.dataActual[3] != true) {
      for (let temp of this.jsonObjectData) {
        if (temp.hasOwnProperty('attributes')) {
          this.flag.push(true);
          for (let t of temp.attributes) {
            t['value'] = '';
            t['description'] = '';
          }
        }
      }
    }
    this.jsonFormData = this.jsonObjectData;
  }
  onEditPrevious() {
    this.inc--;
  }
  onEditNext() {
    let v = true;
    for (let i of this.jsonFormData[this.inc].attributes) {
      if (i.type == 'radio') {
        if ((i.value == 1 || i.value == 5) && i.description == '') {
          v = false;
        }
      }
    }
    if (v) {
      let temp = true;
      if (this.inc < this.jsonFormData.length - 1) {
        this.isDisabled = true;
        for (let j of this.jsonFormData[this.inc].attributes) {
          if (j.value === '') {
            temp = false;
          }
        }
        this.flag[this.inc] = temp;
      }
      this.inc++;

      if (this.inc == this.jsonFormData.length - 1) {
        for (let i of this.flag) {
          this.isDisabled = this.isDisabled && i;
        }
      }
    } else {
      this.toast.warning(
        'Some information is missing. Make sure you have filled all the required fields before saving the feedback',
        'warning'
      );
    }
  }

  ngOnInit(): void {
    this.dataActual[6] ? (this.isProbation = true) : (this.isProbation = false);
    this.dataActual[7] == 'REVERSE_PROBATION'
      ? (this.isReverseProbation = true)
      : (this.isReverseProbation = false);

    this.feedbackService.setCurrentStatusOfIsDisable(false);
    for (let temp of this.jsonFormData) {
      if (temp.hasOwnProperty('attributes')) {
        let temp_array = [];
        this.flag.push(true);
        for (let t of temp.attributes) {
          if (temp.hasOwnProperty('attributes')) {
            if (t.type == 'radio') this.numberOfQuestion++;
          }
          if (t.value != '') {
            temp_array.push(parseInt(t.value));
            this.totalScore += parseInt(t.value);
          } else {
            temp_array.push(0);
          }
        }
        this.arrayOfAnswer.push(temp_array);
      }
    }
  }

  ngAfterViewChecked(): void {
    this.showScrollText = this.showScroll();
    this.cdRef.detectChanges();
  }

  sendProbationFeedback() {
    var flag = false;
    this.jsonFormData.valid;
    for (let temp of this.jsonFormData) {
      if (temp.hasOwnProperty('attributes')) {
        for (let t of temp.attributes) {
          if (t.Required && t.value === '') {
            flag = true;
            this.toast.error('Please answer mandatory question', 'Error');
            break;
          } else if (
            t.Required &&
            t.type === 'radio' &&
            (t.value === '1' || t.value === '5')
          ) {
            if (t.description === '') {
              this.toast.error('Comment is mandatory', 'Error');
              flag = true;
              break;
            }
          }
        }
      }
    }
    if (!flag) {
      this.feedbackService
        .submitProbationFeedback(this.dataActual[1], this.jsonFormData)
        .subscribe(
          (res) => {
            this.toast.success('Successfully Submitted', 'Saved');
            this.feedbackService.filter('');
            this.dialogRef.close();
          },
          (error) => {
            this.toast.error(
              'Please ensure all the checkboxes are filled',
              'Error'
            );
          }
        );
    }
  }

  send() {
    if (this.dataActual[7] == 'REVERSE_PROBATION') {
      this.feedbackService
        .submitProbationFeedback(this.dataActual[1], this.jsonFormData)
        .subscribe(
          (res) => {
            this.toast.success('Successfully Submitted', 'Saved');
            this.feedbackService.filter('');
            this.dialogRef.close();
          },
          (error) => {
            this.toast.error(
              'Please ensure all the checkboxes are filled',
              'Error'
            );
          }
        );
    } else {
      if (this.dataActual[7] == 'ORPHAN_FEEDBACK') {
        this.feedbackSummeryOpen(this.dataActual[1], this.jsonFormData);
      } else {
        this.feedbackService
          .submitFeedback(this.dataActual[1], this.jsonFormData)
          .subscribe(
            (res) => {
              this.toast.success('Successfully Submitted', 'Saved');
              this.feedbackService.filter('');
            },
            (error) => {
              this.toast.error(
                'Please ensure all the checkboxes are filled',
                'Error'
              );
            }
          );
      }
    }
  }

  feedbackSummeryOpen(feedbackId, formData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '40%';
    dialogConfig.width = '50%';
    dialogConfig.data = [feedbackId, formData];
    const dialogRef = this.dialog
      .open(FeedbackSummeryComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.feedbackService
            .submitFeedback(this.dataActual[1], this.jsonFormData)
            .subscribe(
              (res) => {
                this.toast.success('Successfully Submitted', 'Saved');
                this.feedbackService.filter('');
                this.dialogRef.close();
              },
              (error) => {
                this.toast.error(
                  'Please ensure all the checkboxes are filled',
                  'Error'
                );
              }
            );
        } else {
          this.toast.error('Please submit Feedback Summary', 'Error');
        }
      });
  }

  save() {
    this.feedbackService
      .saveFeedback(this.dataActual[1], this.jsonFormData)
      .subscribe((res) => {});
    this.dialogRef.close();
  }

  save1() {
    //
    let v = true;
    for (let i of this.jsonFormData[this.inc].attributes) {
      if ((i.value == 1 || i.value == 5) && i.description == '') {
        v = false;
      }
    }
    if (v) {
      let temp = true;
      if (this.inc < this.jsonFormData.length - 1) {
        this.isDisabled = true;
        for (let j of this.jsonFormData[this.inc].attributes) {
          if (j.value === '') {
            temp = false;
          }
        }
        this.flag[this.inc] = temp;
      }
      this.inc++;

      if (this.inc == this.jsonFormData.length - 1) {
        for (let i of this.flag) {
          this.isDisabled = this.isDisabled && i;
        }
      }

      this.feedbackService
        .saveFeedback(this.dataActual[1], this.jsonFormData)
        .subscribe((res) => {
          this.isSavedDraft = true;
        });
    } else {
      this.toast.warning(
        'Some information is missing. Make sure you have filled all the required fields before saving the feedback',
        'warning'
      );
    }
  }

  closeDialog() {
    // checking feedback if real-time feedback and save draft and is opened from profile
    if (
      this.dataActual[7] == FeedbackType.ORPHAN_FEEDBACK &&
      this.isSavedDraft &&
      this.dataActual[8]
    ) {
      let notification = {
        id: this.dataActual[1],
        Name: this.dataActual[2],
      };
      this.notificationService
        .createNotificationForRTF(notification)
        .subscribe((res) => {
          this.refreshService.triggerRefresh('pages');
        });
    }
    this.dialogRef.close();
  }

  checkError = (controlName: string, errorName: string) => {
    return this.myForm.controls[controlName].hasError(errorName);
  };
  recalculateScore(event) {
    this.arrayOfAnswer[this.inc][event.item] = parseInt(event.score);
    this.totalScore = 0;
    this.arrayOfAnswer.forEach((p) => {
      p.forEach((s) => {
        this.totalScore += s;
      });
    });
  }

  showScroll = () => {
    if (
      this.elementView1 &&
      this.elementView &&
      this.elementView1.nativeElement &&
      this.elementView.nativeElement
    ) {
      let nativeElement = this.elementView.nativeElement,
        nativeElement1 = this.elementView1.nativeElement;
      return (
        nativeElement1.offsetHeight > nativeElement.offsetHeight &&
        nativeElement.offsetHeight + nativeElement.scrollTop <
          nativeElement1.offsetHeight - 10
      );
    }
    return false;
  };

  send1() {
    this.toast.warning(
      'Make sure you have answered all the feedback questions before submitting the form.',
      'warning'
    );
    this.feedbackService.setCurrentStatusOfIsDisable(true);
  }
}
