<div class="row">
  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card left-extra-margin" *ngIf="this.userAbout.length !== 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\about.png">
          <span class="head ml-3">About</span>
        </div>
        <div class="mr-3" *ngIf="selfLoggedin">
          <mat-icon (click)="editAboutDialog()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>

      <div class="aboutscroll">
        <p class="about">{{ this.userAbout }}</p>
      </div>
    </mat-card>

    <mat-card class="main-card left-extra-margin" *ngIf="this.userAbout.length == 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\about.png">
          <span class="head ml-3">About</span>
        </div>
        <div class="mr-3" *ngIf="selfLoggedin">
          <mat-icon (click)="editAboutDialog()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>

      <div *ngIf="this.userAbout.length == 0" class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-3">No Data Available</label>
      </div>

      <div>
        <label *ngIf="this.userAbout.length !== 0" class="no_data_label">{{
          this.userAbout
          }}</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card" *ngIf=" this.skilldto && this.skilldto.id">
      <div class=" header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\skills.png">
          <span class="head ml-3">Professional Skills</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editProfessionalskillsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="contentscroll">
        <div class="professiona_mat_body mt-2 mr-2">
            <div class="row">
              <div class="col-12">
                <div>
                  <label class="Professional_name">Primary Skills</label>
                </div>
                <div>
                  <label class="skills">{{ spaceOutArray(this.skilldto.primarySkills) }}</label>
                </div>
              </div>
              <div class="col-12">
                <div>
                  <label class="Professional_name">Secondary Skills</label>
                </div>
                <div >
                  <label class="skills">{{ spaceOutArray(this.skilldto.secondarySkills) }}</label>
                </div>
              </div>
            </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="main-card" *ngIf="!this.skilldto || !this.skilldto.id">
      <div class=" header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\skills.png">
          <span class="head ml-3">Professional Skills</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editProfessionalskillsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>

      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card left-extra-margin" *ngIf="this.certificatedto.length !== 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\certification.png">
          <span class="head ml-3">Certifications</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editCertificationsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>

      <div class="contentscroll2">
        <div class="row mt-2 border_div" *ngFor="let e of certificatedto">
          <div class="logo_div">
            <img class="logo_img" src="assets/icons/certificate_spotlight.png" />
          </div>

          <div class="certicate_header_lables">
            <label class="certificate_label">Certificate Name</label>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip="{{ e.documentName }}" class="certificate_name">{{ e.documentName }}</label>
          </div>

            <div class="certicate_header_lables">
              <label class="certificate_label">Issued By</label>
              <label class="certificate_name">{{e.dateOfIssue | date: "MMM yyyy"}}</label>
            </div>

            <div class="valid-upto">
              <label class="certificate_label" *ngIf="e.validUpto">Valid Upto</label>
              <label class="certificate_name" [ngClass]="{'expired': isExpired(e.validUpto)}">{{e.validUpto | date:
                "MMM yyyy"}}</label>
            </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="main-card left-extra-margin" *ngIf="this.certificatedto.length == 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\certification.png">
          <span class="head ml-3">Certifications</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editCertificationsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>

      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card" *ngIf="this.positionHistory.length !== 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\position.png">
          <span class="head ml-3">Position History</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editPositionDetails()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="contentscroll1">
        <div class="history_mat_body" *ngFor="let p of this.positionHistory">
          <div>
            <span *ngIf="p.startDate !== null" class="position_details_heder">
              Start Date: {{ p.startDate | date : "dd-MM-yyyy" }}
            </span>
            <br />
            <label class="position_details_name mt-2">{{
              this.jobTitle[p.jobTitle]
              }}</label>
          </div>
          <div>
            <span *ngIf="p.endDate !== null" class="position_details_heder">
              End Date: {{ p.endDate | date : "dd-MM-yyyy" }}
            </span>
            <br>
            <label class="position_details_location mt-2">{{p.baseLocation}}, {{p.geoLocation | titlecase}}</label>
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="main-card" *ngIf="this.positionHistory.length == 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\position.png">
          <span class="head ml-3">Position History </span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editPositionDetails()"><img class="edit_logo" src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card left-extra-margin" *ngIf="this.educationdetailsdto.length !== 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\qualification.png">
          <span class="head ml-3">Educational Qualification</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEducationalQualificationsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" />
          </mat-icon>
        </div>
      </div>

      <div class="contentscroll1">
        <div *ngFor="let e of educationdetailsdto" class="history_mat_body">
          <div>
            <span class="role_heder">Start Date: {{ e.yearOfBeginning | date : "dd-MM-yyyy" }}</span>

            <br />
            <label class="role_name mt-2">{{ e.courseName }}</label>
            <br />
            <label class="university_name mt-2">{{ e.universityName }}</label>
          </div>
          <div>
            <span class="role_heder">End Date: {{ e.yearofCompletion | date : "dd-MM-yyyy" }}</span>
            <br />
          </div>
        </div>
      </div>
    </mat-card>

    <mat-card class="main-card left-extra-margin" *ngIf="this.educationdetailsdto.length == 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\qualification.png">
          <span class="head ml-3">Educational Qualification</span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEducationalQualificationsDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" />
          </mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 small-size-device">
    <mat-card class="main-card" *ngIf="this.preempdto.length !== 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\history.png">
          <span class="head ml-3">Employment History </span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmploymentHistoryDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="contentscroll1">
        <div *ngFor="let e of preempdto" class="history_mat_body">
          <div>
            <span class="role_heder">Job Title</span>
            <br />
            <label class="role_name mt-1">{{ e.jobTitle | titlecase }}</label>
          </div>
          <div>
            <span class="role_heder1"> {{ e.comapanyName | titlecase }}</span>
            <br />
            <label class="position_details_location mt-1">{{ this.preempexp[e.id] }} </label>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="main-card" *ngIf="this.preempdto.length == 0">
      <div class="header">
        <div class="ml-3">
          <img class="header-icon" src="assets\icons\history.png">
          <span class="head ml-3">Employment History </span>
        </div>
        <div class="mr-3" *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmploymentHistoryDialog()"><img class="edit_logo"
              src="assets/icons/edit.png" /></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/images/no-data-available.png" />
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>
</div>