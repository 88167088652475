<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <form [formGroup]="feedbackClosure">
        <div class="header-div">
          <div class="flex">
            <div class="coloums">
              <img
                src="assets/icons/default-avatar.jpg"
                class="img-container"
              />
            </div>
            <div class="col-3">
              <label class="header_name">{{
                this.userIdYear.userdto.firstName +
                  " " +
                  this.userIdYear.userdto.lastName
              }}</label>
              <p class="count">ID {{ this.userIdYear.userdto.employeeId }}</p>
            </div>

            <div class="col-3 feedback-group-div">
              <label class="asset-common">Score </label>
              <p class="reportee-score">
                {{ this.userIdYear.userdto.totalScore | number : "1.2-2" }}/5
              </p>
            </div>
            <div class="col-3 feedback-group-div">
              <label class="asset-common">Feedback Group </label>
              <p class="reportee-score">
                {{ this.userIdYear.userdto.feedbackForms }}
              </p>
            </div>
            <div>
              <div class="cm-notes">
                <label class="asset-common">Notes </label>
                <p class="reportee-score cm-notes-data">
                  {{ this.feedbackdto.cmNote }}
                </p>
              </div>
            </div>
          </div>

          <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
          </div>
        </div>
        <hr />
        <div class="ckeditor">
          <ckeditor
            [config]="config"
            [editor]="Editor"
            formControlName="note"
            class="ck-text"
          ></ckeditor>
        </div>
        <div class="save-btn-div">
          <button
            mat-raised-button
            class="draft-btn button"
            (click)="callSaveApi(false)"
          >
            Save as Draft
          </button>
          <button
            mat-raised-button
            class="save-btn button"
            (click)="callSaveApi(true)"
          >
            Preview
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
