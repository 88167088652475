import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RedirectService } from 'src/app/services/redirect.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import * as taskActions from '../../store/actions/task.action';
import * as fromTask from '../../store/reducers/task.reducer';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TaskComponent } from './task/task.component';
import { TaskdialogComponent } from './taskdialog/taskdialog.component';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  pendingTasks$ = this.store.pipe(select(fromTask.selectPendingTasks));
  wfoTasksCount$ = this.store.pipe(select(fromTask.wfoTasksCount));
  lmsTasksCount$ = this.store.pipe(select(fromTask.lmsTasksCount));
  performanceTasksCount$ = this.store.pipe(
    select(fromTask.performanceTasksCount)
  );
  profileTasksCount$ = this.store.pipe(select(fromTask.profileTasksCount));
  taskState$ = this.store.pipe(select(fromTask.selectTask));
  p: number = 1;
  index: any;
  hasCmRights: boolean = false;

  constructor(
    private store: Store<any>,
    private add: RedirectService,
    private refreshService: RefreshService,
    private dialog: MatDialog,
    private userDetailsService: UserDetailsService
  ) {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'tasks') {
        this.loadTasks();
      }
    });
    this.add.shareDataSubject.subscribe((result) => {
      this.index = result;
    });
  }
  ngOnInit(): void {
    this.store.dispatch(taskActions.loadTasks());
    this.hasCmRights = this.userDetailsService.hasRole(['ROLE_CAREER_MANAGER']);
  }

  loadTasks() {
    this.store.dispatch(taskActions.loadTasks());
  }

  taskListForSingleModule(moduleName) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '90%';
    dialogConfig.height = '60%';
    dialogConfig.data = { moduleName: moduleName };
    const dialogRef = this.dialog.open(TaskdialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {},
      () => {
        this.ngOnInit();
      }
    );
  }
}
