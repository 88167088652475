<mat-card class="main-card">
  <!-- WFO header -->
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/wfo-icon.svg" />
          <div class="wfo-heading">Working from Office</div>
        </div>
        <div>
          <img (click)="openWfoDetail()" class="svg-icon-wfo" src="assets/icons/dashboard-icons/navigation-icon.svg" />
        </div>
      </div>
    </div>
  </div>

  <!-- WFO runner text -->
  <div class="row">
    <div class="col-md-12">
      <div class="runner-text-main">
        <marquee *ngIf="LastApprovedDetailsOfUser.length != 0 || AllHistoryOfDirectreporteeList.length != 0" 
          width="87%" height="30px" direction="left" class="runner-text">
          Please remember to log your office presence after your arrival at the office.
        </marquee>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!-- No user or reportee data found -->
      <div *ngIf="LastApprovedDetailsOfUser.length == 0 && AllHistoryOfDirectreporteeList.length == 0" class="empty-wfo-div">
        <div class="empty-text">
          Please remember to log your office presence after your arrival at the office.
        </div>
        <!-- <button class="apply apply-wfo-btn" (click)="applyWFO()">Apply</button> -->
        <button class="blue-button apply-wfo-btn" (click)="applyWFO()">Apply</button>

      </div>
      
      <!-- No last approved user data -->
      <div *ngIf="LastApprovedDetailsOfUser.length == 0 && AllHistoryOfDirectreporteeList.length != 0" class="pink-color">
        No WFO data found for you.
      </div>
      
      <!-- Last approved user data -->
      <div *ngIf="LastApprovedDetailsOfUser.length != 0" class="approved-user-details">
        <div class="single-reportee-detail-container">
          <div class="img-container-div">
            <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
            <div class="name-container">
              {{ LastApprovedDetailsOfUser.firstName | titlecase }}&nbsp;{{
              LastApprovedDetailsOfUser.lastName | titlecase
              }}
              <div class="back-color">
                ({{
                LastApprovedDetailsOfUser.location
                | titlecase
                | underScoreToSpace
                }})
              </div>
            </div>
          </div>
          <div class="leave-status">
            Date
            <div>{{ LastApprovedDetailsOfUser.lastApprovedDate }}</div>
          </div>
        </div>
      </div>

      <!-- Reportee WfO details heading -->
      <div class="pink-color reportee-wfo-heading" *ngIf="AllHistoryOfDirectreporteeList.length != 0">
        Team members working from office {{ selectedMonth }} - {{ selectedYear }}
      </div>

      <!-- Reportee WFO with no data -->
      <div class="pink-color" *ngIf="LastApprovedDetailsOfUser.length != 0 && AllHistoryOfDirectreporteeList.length == 0">
        No team members working from office in {{ selectedMonth }}.
      </div>

      <!--  Reportees WFO with data -->
      <div class="tableFixHead" *ngIf="AllHistoryOfDirectreporteeList && AllHistoryOfDirectreporteeList.length != 0">
        <div *ngFor="let reportee of AllHistoryOfDirectreporteeList | orderBy : 'date'">
          <div class="single-reportee-detail-container">
            <div class="img-container-div">
              <img class="noemployee_img" src="assets/icons/default-avatar.jpg" alt="" />
              <div class="name-container">
                {{ reportee.firstName | titlecase }}&nbsp;{{
                reportee.lastName | titlecase
                }}
                <div class="back-color">
                  {{ reportee.employeeId }}({{
                  reportee.location | titlecase | underScoreToSpace
                  }})
                </div>
              </div>
            </div>
            <div class="leave-status">
              Date
              <div>{{ reportee.lastApprovedDate }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</mat-card>