<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <label *ngIf="this.data[0] == null" class="header_name"><img src="assets/svg/new-employee.svg"/> Add New Employee</label>
      <label *ngIf="this.data[0]" class="header_name"><img src="assets/svg/new-employee.svg"/> Employee</label>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()"><img src="assets/icons/close.png"></button>
    </div>
  </div>
  <hr />

  <div class="row main-div">
    <div class="col-md-3">
      <div class="profile"><label><img src="assets/svg/profile.svg"/> Photo</label></div>
      <div class="upload_img_maindiv">
        <figure>
          <div class="upload_img_cation_alignment" (click)="fileInput.click()">
            <img class="upload_img" [(src)]="binaryImgString" *ngIf="photo" />
            <img class="upload_img" src="assets/images/blue_altair_logo.png" alt="" *ngIf="!photo" />
            <label class="remove_img_text" *ngIf="this.imageError && !this.photo">{{ this.imageError }}</label>
            <figcaption class="upload_text" *ngIf="!photo">Upload</figcaption>
            <input type="file" id="my_file" style="display: none" accept=".jpg,.png"
              (change)="readURL($event.target.files)" #fileInput />
          </div>
          <figcaption class="remove_img_text" *ngIf="photo" (click)="setDefaultImg()">
            Remove File
          </figcaption>
        </figure>
      </div>
      <div class="employee-id"><label *ngIf="this.data[0] && this.data[0].employeeId">ID: {{this.data[0].employeeId | uppercase}}</label></div>
    </div>

    <div class="col-md-9">
      <form [formGroup]="newEmployeeDetails" (ngSubmit)="onSubmit()">
        <div class="contentScroll">
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>First Name </mat-label>
                <input matInput formControlName="firstName" autocomplete="off" style="text-transform: capitalize"
                  pattern="^[a-zA-Z ]*$" />
                <mat-error *ngIf="checkError('firstName', 'required')">Required</mat-error>
                <mat-error *ngIf="checkError('firstName', 'pattern')">Invalid pattern</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Middle Name</mat-label>
                <input matInput formControlName="middleName" autocomplete="off" style="text-transform: capitalize"
                  pattern="^[a-zA-Z ]*$" />
                <mat-error *ngIf="checkError('middleName', 'pattern')">Invalid pattern</mat-error>

              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" autocomplete="off" style="text-transform: capitalize"
                  pattern="^[a-zA-Z ]*$" />
                <mat-error *ngIf="checkError('lastName', 'required')">Required</mat-error>
                <mat-error *ngIf="checkError('lastName', 'pattern')">Invalid pattern</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Gender <span class="contentRed"></span></mat-label>
                <mat-select formControlName="gender">
                  <mat-option *ngFor="let gender of this.genders" [value]="gender">
                    {{ gender }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('gender', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Date of Birth <span class="contentRed"></span></mat-label>
                <input matInput [matDatepicker]="picker3" placeholder="dd/mm/yyyy" formControlName="dob"
                  autocomplete="off" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error *ngIf="checkError('dob', 'required') &&  !checkError('dob', 'matDatepickerParse')">Required</mat-error>
                <mat-error *ngIf="checkError('dob', 'matDatepickerParse')">Invalid Date</mat-error>              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Marital Status <span class="contentRed"></span></mat-label>
                <mat-select formControlName="maritalStatus">
                  <mat-option *ngFor="let maritalStatus of this.maritalStatusTypes" [value]="maritalStatus">
                    {{ maritalStatus }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('maritalStatus', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Email Id<span class="contentRed"></span></mat-label>
                <input matInput formControlName="email" autocomplete="off" (ngModelChange)="onEmailTextTyped($event)"
                  required />
                <mat-error *ngIf="checkError('email', 'pattern')">Invalid email</mat-error>
                <mat-error *ngIf="checkError('email', 'required')">Required</mat-error>
                <mat-error *ngIf="!this.isValidEmail">Email is already in use!</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Mobile Number <span class="contentRed"></span></mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us']" [enablePlaceholder]="true"
                  [enableSearch]="true" name="phone" (countryChanged)="countryChangedEvent($event,'mobileNo')"
                  formControlName="mobileNo" #phone (focusout)="focusOutMobileField('mobileNo')"
                  required>
                </ngx-mat-intl-tel-input>
                <mat-hint>
                  <label> E.g. : {{ phone.selectedCountry.placeHolder }} </label>
                </mat-hint>
                <mat-error *ngIf="
                    this.newEmployeeDetails.get('mobileNo').errors?.ZeroLength
                  ">
                  Required
                </mat-error>
                <mat-error *ngIf="
                    this.newEmployeeDetails.get('mobileNo').errors?.InvalidNumber
                  ">
                  Invalid Mobile Number
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Alternate Mobile No <span class="contentRed"></span></mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us']" [enablePlaceholder]="true"
                  [enableSearch]="true" name="phone1" (countryChanged)="countryChangedEvent($event,'alternateMobileNo')"
                  formControlName="alternateMobileNo" #phone1 (focusout)="focusOutMobileField('alternateMobileNo')"
                  ></ngx-mat-intl-tel-input>
                <mat-hint>
                  <label> E.g. : {{ phone1.selectedCountry.placeHolder }} </label>
                </mat-hint>
                <mat-error *ngIf="
                    this.newEmployeeDetails.get('alternateMobileNo').errors?.InvalidNumber
                  ">
                  Invalid Mobile Number
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Date of Joining <span class="contentRed"></span></mat-label>
                <input matInput [matDatepicker]="picker" placeholder="dd/mm/yyyy" formControlName="dateOfJoining"
                  autocomplete="off"  (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="checkError('dateOfJoining', 'required') &&  !checkError('dateOfJoining', 'matDatepickerParse')">Required</mat-error>
                <mat-error *ngIf="checkError('dateOfJoining', 'matDatepickerParse')">Invalid Date</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Probation Period <span class="contentRed"></span></mat-label>
                <mat-select formControlName="probationPeriod">
                  <mat-option *ngFor="let probationPeriod of this.probationPeriods" [value]="probationPeriod"
                    (click)="onProbationPeriodSelect(probationPeriod)">
                    {{ probationPeriod }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('jobTitle', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Confirmation Date <span class="contentRed"></span></mat-label>
                <input matInput [matDatepicker]="picker1" placeholder="dd/mm/yyyy" formControlName="confirmationDate" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="checkError('confirmationDate', 'required') &&  !checkError('confirmationDate', 'matDatepickerParse')">Required</mat-error>
                <mat-error *ngIf="checkError('confirmationDate', 'matDatepickerParse')">Invalid Date</mat-error>
              </mat-form-field>
            </div>
          </div>
          
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Employment Type <span class="contentRed"></span></mat-label>
                <mat-select formControlName="employmentType">
                  <mat-option *ngFor="let employmentType of this.employmentTypes" [value]="employmentType">
                    {{ employmentType }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('employmentType', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Job Title <span class="contentRed"></span></mat-label>
                <mat-select formControlName="jobTitle">
                  <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip=" {{ jobTitle }}">
                    {{ jobTitle }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('jobTitle', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Capability <span class="contentRed"></span></mat-label>
                <mat-select formControlName="department">
                  <mat-option *ngFor="let department of this.departments" [value]="department" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip=" {{ department }}">
                    {{ department }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('department', 'required')">Required</mat-error>
              </mat-form-field>
            </div>

          </div>
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Geo Location <span class="contentRed"></span></mat-label>
                <mat-select formControlName="geoLocation" (selectionChange)="onGeoLocationChange($event.value)">
                  <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                  matTooltip=" {{ geoLocation }}">
                    {{ geoLocation }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('geoLocation', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Base Location <span class="contentRed"></span></mat-label>
                <mat-select formControlName="baseLocation">
                  <mat-option *ngFor="let location of locations" [value]="location">
                    {{ location }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('baseLocation', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Current Location <span class="contentRed"></span></mat-label>
                <input matInput formControlName="officeLocation" autocomplete="off" style="text-transform: capitalize"/>
                <mat-error *ngIf="checkError('officeLocation', 'required')">Required</mat-error>
                <mat-error *ngIf="checkError('officeLocation', 'pattern')">Invalid pattern</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row height-60">
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Talent Buddy</mat-label>
                <input matInput [matAutocomplete]="talentBuddyAuto" formControlName="talentBuddy"
                  style="text-transform: capitalize" (input)="checkTalentValidity()" #buddyInput />
                <mat-autocomplete #talentBuddyAuto="matAutocomplete" [displayWith]="displayFnCareerManager">
                  <mat-option *ngFor="let talentBuddy of filteredTalentBuddy | async" [value]="talentBuddy">
                    <span style="margin-left: 10px">{{
                      talentBuddy.firstName +
                      " " +
                      talentBuddy.lastName +
                      " (" +
                      talentBuddy.employeeId +
                      ")"
                      }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="checkError('talentBuddy', 'notInList')">
                  Talent Buddy not found in the list
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Career Manager</mat-label>
                <input matInput [matAutocomplete]="careerManagerAuto" formControlName="careerManager" Required
                  style="text-transform: capitalize" (input)="checkManagerValidity()" #managerInput/>
                <mat-autocomplete #careerManagerAuto="matAutocomplete" [displayWith]="displayFnCareerManager" (optionSelected)="onCareerManagerSelected($event)">
                  <mat-option *ngFor="let manager of filteredCareerManager | async" [value]="manager">
                    <span style="margin-left: 10px">{{ manager.firstName + " " + manager.lastName + " " + "(" + manager.employeeId + ")" }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="checkError('careerManager', 'required')">
                  Required
                </mat-error>
                <mat-error *ngIf="checkError('careerManager', 'notInList')">
                  Career Manager not found in the list
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 mb-2">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Roles</mat-label>
                <mat-select formControlName="roles" multiple>
                  <mat-option *ngFor="let role of rolesList" [value]="role" [disabled]="
                      role === 'Role User' || role === 'Role Career Manager'
                    " [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                    matTooltip=" {{ role }}">{{ role }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('roles', 'required')">Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="submit_button_main">
              <button class="blue-button" type="submit">
                <span>{{ this.saveBtnLabel }}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>