<ng-container *ngIf="this.nodata == true; else elseTemplate">
  <div class="nothing-data">
    <img class="no-project-img" src="assets/images/No-project-data.png" />

    <button class="Fetch-btn" [disabled]="syncButtonDisabled" (click)="onClickButton()">
      <span class="fetch-msg">{{
        syncButtonDisabled ? "Syncing..." : "Sync"
        }}</span>
    </button>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <ng-container *ngIf="this.isDataPresent === false; else abc">
    <div class="main_div">
      <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
      <label class="loading_label mt-5">Loading...</label>
    </div>
  </ng-container>
  <ng-template class="main" #abc>
    <div class="col-md-12 pl-0 pr-0">
      <mat-card class="mat_card">
        <div class="search_main">
          <mat-icon class="search-icon mt-1 mr-4">search</mat-icon>
          <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="" />
        </div>

        <div class="sync-btn hover-pointer">
          <img class="icon" src="assets/icons/retry.png" [disabled]="syncButtonDisabled" (click)="onClickButton()" [matTooltip]="
            syncButtonDisabled
              ? 'Sync in progress, please wait.'
              : 'Click to sync'
          ">

        </div>
      </mat-card>
    </div>
    <div *ngIf="this.mobileview == false" class="card-container">
      <mat-card class="matcardprojectdetail" [ngClass]="todayDate > project.endDate ? 'inactive' : 'active'" *ngFor="
          let project of this.projectList
            | filter : searchText
            | paginate : { itemsPerPage: 6, currentPage: p }">
        <mat-card-content>
          <div (click)="onViewProject(project)">
            <div class="row">
              <div class="col">
                <h5 class="project-name">Project Name</h5>
                <p>{{ project.projectName | underScoreToSpace | titlecase }}</p>
              </div>
              <div class="col">
                <h5 class="project-name">Client Name</h5>
                <p>{{ this.clientIdName[project.clientId] }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5 class="project-name">Start Date</h5>
                <p>{{ project.startDate | date : "dd-MM-yyyy" }}</p>
              </div>
              <div class="col">
                <h5 class="project-name">End Date</h5>
                <p>{{ project.endDate | date : "dd-MM-yyyy" }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h5 class="project-name">Project Category</h5>
                <p>
                  {{ project.projectCategory | underScoreToSpace | titlecase }}
                </p>
              </div>
            </div>
          </div>
          <div class="members-div">
            <div class="row members" *ngFor="let temp of map.get(project.id)">
              <img src="assets/icons/default-avatar.jpg" class="img-container" [matTooltipPosition]="position.value"
                [matTooltipClass]="position.value" [matTooltip]="temp" />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="this.mobileview == true" class="card-container">
      <ng-container *ngIf="this.nodata == true; else elseTemplate">
        <img class="no-project-img" src="assets/images/No-project-data.png" />
      </ng-container>
      <ng-template #elseTemplate>
        <mat-card class="matcardprojectdetail" (click)="onViewProject(project)"
          [ngClass]="todayDate > project.endDate ? 'inactive' : 'active'" *ngFor="
            let project of this.projectList
              | filter : searchText
              | paginate : { itemsPerPage: 4, currentPage: p }
          ">
          <mat-card-content>
            <div class="row mt-3">
              <div class="col">
                <h5 class="project-name">Project Name</h5>
              </div>
              <div class="col">
                <p>{{ project.projectName | underScoreToSpace | titlecase }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <h5 class="project-name">Client Name</h5>
              </div>
              <div class="col">
                <p>
                  {{
                  this.clientIdName[project.clientId]
                  | underScoreToSpace
                  | titlecase
                  }}
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <h5 class="project-name">End Date</h5>
              </div>
              <div class="col">
                <p>{{ project.endDate | date : "dd-MM-yyyy" }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <h5 class="project-name">Start Date</h5>
              </div>
              <div class="col">
                <p>{{ project.startDate | date : "dd-MM-yyyy" }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <h5 class="project-name">Project Category</h5>
              </div>
              <div class="col">
                <p>
                  {{ project.projectCategory | underScoreToSpace | titlecase }}
                </p>
              </div>
            </div>
            <div class="members-div" [style.background-color]="
                isEndDateInFuture(project.endDate) ? 'white' : ''
              ">
              <div class="row members" *ngFor="let temp of map.get(project.id)">
                <img src="assets/icons/default-avatar.jpg" class="img-container" [matTooltipPosition]="position.value"
                  [matTooltipClass]="position.value" [matTooltip]="temp" />
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-template>
    </div>

    <pagination-controls *ngIf="this.nodata == false" class="pagination" (pageChange)="p = $event">
    </pagination-controls>
  </ng-template></ng-template>