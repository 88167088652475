<div>
  <div class="reportee-detail-div display-flex border-div" *ngIf="this.userdto">
    <div class="reportee-name-id">
      {{ this.userdto.firstName + " " + this.userdto.lastName | titlecase }} (ID
      {{ this.userdto.employeeId }})
      {{ this.userdto.jobTitle | underScoreToSpace | titlecase }}
    </div>
    <div class="close" (click)="closeDialog()">Close</div>
  </div>
  <div class="border-div reportee-perf-div">
    <div class="display-flex tab-btns" *ngIf="switchedTab == 'GOAL'">
      <div class="full-width">
        <button mat-raised-button class="blue-button" (click)="openAssignGoalDialogue()"
          *ngIf="this.selected == this.currentYear">
          Add New Goal
        </button>
      </div>
      <div class="year_dropdown_cm">
        <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
          <mat-option *ngFor="let year of years" [value]="year">{{
            year
            }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="display-flex tab-btns" *ngIf="switchedTab == 'FEEDBACK'">
      <div class="display-flex annual-feedback-btns">
        <button class="blue-button" (click)="openFeedbackDialog()" *ngIf="
            !isHideConfigureFeedbackBtn && this.disablefeedbackForm == false
          ">
          Initiate new Feedback
        </button>
        <div class="score">
          <div class="display-flex annual-fb-score">
            <button mat-icon-button matSuffix type="button" (click)="this.hide = !this.hide"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" class="hide-icon">
              <img *ngIf="hide" src="assets/icons/unhide.png" />
              <img *ngIf="!hide" src="assets/icons/eye.png" />
            </button>
            <label class="score-visibility">
              {{ hide ? "Show" : "Hide" }}</label>
          </div>

          <label *ngIf="this.hide == !true" class="score-value">
            <span class="score-lbl">Score</span>
            {{ this.userdto.totalScore | number : "1.2-2" }}/5</label>
          <label *ngIf="this.hide == true" class="hide">●●●</label>
        </div>
        <button class="btn-white btn-notes" (click)="EditFeedbackNotes()">
          <img src="assets/icons/notes.png" />
          <span class="notes-lbl">Notes</span>
        </button>
      </div>
      <button class="btn-multicolor btn-close-feedback close-annual-feedback" (click)="CloseFeedback()"
        *ngIf="this.disablefeedbackForm == false">
        Close Feedback
      </button>
    </div>

    <div class="display-flex tab-btns" *ngIf="switchedTab == 'REAL_TIME'">
      <button mat-raised-button class="blue-button" (click)="AssignFeedback()">
        Real-time Feedback
      </button>
    </div>

    <div class="display-flex tab-btns" *ngIf="switchedTab == 'PROBATION'">
      <div>
        <button class="blue-button" *ngIf="
            isHideProbationFeedbackBtn &&
            !disableProbationfeedbackForm &&
            !hideConfigureProbation
          " (click)="openProbationDialog()">
          Initiate Probation Feedback
        </button>
      </div>
      <button class="btn-white btn-notes" (click)="EditFeedbackNotes()">
        <mat-icon>note_add</mat-icon>
        Notes
      </button>
      <div>
        <button class="btn-multicolor btn-close-feedback" *ngIf="
            isHideProbationFeedbackBtn &&
            !disableProbationfeedbackForm &&
            hideConfigureProbation
          " (click)="closeProbationFeedback()">
          Close Probation Feedback
        </button>
      </div>
    </div>

    <div class="no-padding full-width">
      <div class="content full-width">
        <mat-tab-group class="tab-group" [selectedIndex]="selectIndexForTab" (selectedTabChange)="onTabChanged($event)"
          style="height: 100%">
          <!-- direct Reportee goal sheet starts here -->
          <mat-tab class="mat-tab-label-active">
            <ng-template mat-tab-label> Goal Sheet </ng-template>
            <div class="goalsheet-tab">
              <app-cm-goal-sheet [userid]="userid"
                (dataFromGoalSheet)="receiveDataFromGoalSheet($event)"></app-cm-goal-sheet>
            </div>
          </mat-tab>

          <!-- Annual Feedback starts here  -->
          <mat-tab class="mat-tab-label-active">
            <ng-template mat-tab-label class="tab-2">
              Annual Feedback
            </ng-template>
            <div class="mt-2 annual-fb-div">
              <mat-accordion>
                <mat-expansion-panel class="annual-fb-card" *ngFor="let data of this.feedbackgroupdata"
                  (opened)="open(data)" (closed)="close(data)">
                  {{ showQuestionBtn(data) }}
                  <mat-expansion-panel-header class="matcardprojectdetail">
                    <div *ngIf="this.mobileview == false" class="annual-feedback-lbl">
                      <div class="coloums-fb-name">
                        <div>Annual Feedback</div>
                        <div class="feedback-name">
                          {{ data.title | titlecase
                          }}{{
                          this.projectIDMapping[data.projectId] | titlecase
                          }}
                        </div>
                      </div>
                      <div class="coloums-weightage">
                        <div class="client-common">Weightage</div>
                        <div class="allocation asset-common">
                          {{ data.groupWeightage * 100 }}%
                        </div>
                      </div>
                      <div class="coloums-score">
                        <div class="client-common">Score</div>
                        <div *ngIf="this.hide == !true" class="count">
                          {{ data.overallScore | number : "1.2-2" }}/5
                        </div>
                        <div *ngIf="this.hide == true" class="hide">●●●</div>
                      </div>
                      <div class="coloums" *ngIf="this.disablefeedbackForm == false">
                        <button class="client-common1" (click)="EditFeedbackDialog(data, true)">
                          Modify
                        </button>
                      </div>
                    </div>
                    <div *ngIf="this.mobileview == true">
                      <div class="flex">
                        <div class="goal-icon-div top-margin">
                          <mat-icon class="mat-icon2"> description </mat-icon>
                        </div>
                        <div class="col">
                          <p class="feedback-name">
                            {{ data.title | titlecase
                            }}{{
                            this.projectIDMapping[data.projectId] | titlecase
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="col coloums">
                          <h5 class="asset-common">Weightage</h5>
                        </div>
                        <div class="col">
                          <p class="asset-common">
                            <label *ngIf="this.temp < 100" class="asset-common green">
                              {{ this.temp }}%</label>
                            <label *ngIf="this.temp > 100" class="asset-common red">
                              {{ this.temp }}%</label>
                            <label *ngIf="this.temp == 100" class="asset-common grey">
                              {{ this.temp }}%</label>
                          </p>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="col coloums">
                          <h5 class="asset-common">Score</h5>
                        </div>
                        <div class="col">
                          <p *ngIf="this.hide == !true" class="asset-common1">
                            {{ data.overallScore | number : "1.2-2" }}/5
                          </p>
                          <p *ngIf="this.hide == true" class="hide">●●●</p>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="col coloums mb-3" *ngIf="this.disablefeedbackForm == false">
                          <button class="asset-common2" (click)="EditFeedbackDialog(data, this.isOpen)">
                            Manage Feedback
                          </button>
                        </div>
                      </div>
                      <div class="flex mb-4">
                        <div class="col">
                          <mat-icon *ngIf="!this.opendiv">
                            expand_more
                          </mat-icon>
                          <mat-icon *ngIf="this.opendiv">
                            expand_less
                          </mat-icon>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <form [formGroup]="calibratedScoreForm">
                    <table class="table-borderless mystyle1 table">
                      <thead>
                        <tr class="title">
                          <th class="emp-title" style="width: 15%">Employee</th>
                          <th class="emp-title" style="width: 9%">Role</th>
                          <th class="emp-title" style="width: 8%">Status</th>
                          <th class="emp-title">Actual Score</th>
                          <th class="emp-title">Calibrated Score</th>
                          <th class="emp-title">Weightage</th>
                          <th class="emp-title">Weightage Score</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr class="title" formArrayName="array" *ngFor="
                            let feedback of this.opinion().controls;
                            let i = index
                          ">
                          <td [formGroupName]="i" data-label="Employee">
                            <label class="user_name">(ID {{ feedback.value.feedbackFromId }})
                              {{
                              feedback.value.feedbackFrom.split(" ")[0]
                              | titlecase
                              }}</label>
                          </td>
                          <td [formGroupName]="i" data-label="Role">
                            <label class="user_role">{{
                              feedback.value.feedbackRole
                              | underScoreToSpace
                              | titlecase
                              }}</label>
                          </td>

                          <td [formGroupName]="i" data-label="Status">
                            <label class="user_role" *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus !== 'CREATED' &&
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus !== 'DECLINED'
                              ">{{
                              feedback.value.feedbackSubmissionStatus
                              | titlecase
                              }}</label>
                            <label class="user_email1 decline-row" *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus == 'CREATED'
                              ">
                              Pending
                            </label>
                            <label class="declined" *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus == 'DECLINED'
                              ">
                              Declined
                            </label>
                          </td>

                          <td [formGroupName]="i" data-label="Actual Score">
                            <mat-form-field appearance="outline" class="user_email" *ngIf="this.hide == !true">
                              <input class="user_email" matInput formControlName="score" readonly /></mat-form-field>
                            <mat-form-field appearance="outline" class="user_email" *ngIf="this.hide == true">
                              <input class="hide user_email" matInput readonly value="●●●" /></mat-form-field>
                          </td>

                          <td [formGroupName]="i" data-label="Calibrated Score">
                            <mat-form-field appearance="outline" class="input user_email" *ngIf="
                                this.disablefeedbackForm == false &&
                                this.actualscorediv[i]
                              ">
                              <input *ngIf="this.hide == !true" matInput formControlName="calibratedScore"
                                type="number" />
                              <input class="hide" *ngIf="this.hide == true" matInput readonly value="●●●" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="input user_email" *ngIf="
                                this.disablefeedbackForm == false &&
                                !this.actualscorediv[i]
                              ">
                              <input class="hide" *ngIf="this.hide == !true" matInput formControlName="calibratedScore"
                                readonly type="number" />
                              <input class="hide" *ngIf="this.hide == true" matInput readonly value="●●●" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="input user_email"
                              *ngIf="this.disablefeedbackForm == true">
                              <input *ngIf="this.hide == !true" matInput formControlName="calibratedScore" readonly
                                type="number" />

                              <input class="hide" *ngIf="this.hide == true" matInput readonly value="●●●" />
                            </mat-form-field>
                          </td>

                          <td [formGroupName]="i" data-label="Weightage">
                            <mat-form-field appearance="outline" *ngIf="
                                !this.disablefeedbackForm &&
                                calibratedScoreForm.value.array[i]
                                  .feedbackRole != 'SELF_EVALUATION'
                              ">
                              <input matInput formControlName="weightage" type="number"
                                (ngModelChange)="onOpinionWeightagetype(i)" />
                              <mat-error *ngIf="
                                  feedback.get('weightage').errors
                                    ?.InvalidWeightage
                                ">
                                {{ Error }}
                              </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" *ngIf="
                                this.disablefeedbackForm ||
                                calibratedScoreForm.value.array[i]
                                  .feedbackRole == 'SELF_EVALUATION'
                              ">
                              <input matInput formControlName="weightage" readonly />
                              <mat-error *ngIf="
                                  feedback.get('weightage').errors
                                    ?.InvalidWeightage
                                ">
                                {{ Error }}
                              </mat-error>
                            </mat-form-field>
                          </td>

                          <td data-label="Weightage Score">
                            <mat-form-field appearance="outline" *ngIf="this.hide !== true">
                              <input matInput readonly value="{{ this.weitatageScore[i] }}" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" *ngIf="this.hide == true">
                              <input matInput class="hide" value="●●●" readonly />
                            </mat-form-field>
                          </td>

                          <td class="mob-view-dots">
                            <div *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus == 'CREATED'
                              " class="remind" (click)="remind(feedback.value.id, i)"
                              [disabled]="this.reminderStatus[i]">
                              <button class="remind" (click)="remind(feedback.value.id, i)"
                                [disabled]="this.reminderStatus[i]">
                                <img src="assets/icons/remind.png" />

                                <label class="remind-lbl">Remind</label>
                              </button>
                            </div>
                            <div *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus == 'DECLINED'
                              " style="margin-left: 12px" class="view" [matTooltipPosition]="position.value"
                              [matTooltipClass]="position.value" [matTooltip]="
                                calibratedScoreForm.value.array[i]
                                  .feedbackDeclinedReason
                              ">
                              <img src="assets/icons/view.png" />

                              <label style="padding-left: 5px">View</label>
                            </div>
                            <div class="dots-img" style="margin-left: 12px">
                              <img *ngIf="this.actualscorediv[i]" src="assets/icons/3dots.png"
                                [matMenuTriggerFor]="beforeMenu" class="threedots" />
                            </div>

                            <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">
                              <button mat-menu-item (click)="view(feedback)" *ngIf="
                                  calibratedScoreForm.value.array[i]
                                    .feedbackSubmissionStatus === 'SUBMITTED'
                                ">
                                <div>
                                  <label class="view">View</label>
                                </div>
                              </button>
                              <button mat-menu-item (click)="resubmit(feedback.value.id)" *ngIf="this.ifHR">
                                <span class="view">Re-Submit</span>
                              </button>
                            </mat-menu>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div>
                      <div class="button-div">
                        <button [disabled]="this.disablefeedbackForm" class="cancel button" (click)="close(data)">
                          Cancel
                        </button>
                        <button [disabled]="this.disablefeedbackForm" class="save button" (click)="onSubmit()">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>

          <!-- realTime starts here -->
          <mat-tab class="mat-tab-label-active" onclick="changeToProbation('REAL_TIME')">
            <ng-template mat-tab-label> Real-time Feedback </ng-template>
            <div class="RealtimeContent">
              <div class="no-data" *ngIf="realTimefeedbackgroupdata.length == 0">
                No Real-time Feedback Available
              </div>
              <div class="card-box">
                <div class="border-box" *ngFor="let data of this.realTimefeedbackgroupdata">
                  <div class="mystyle">
                    <div class="col">
                      <p class="client-common">Employee</p>
                      <p class="count">
                        (<span>ID</span> {{ data.employeeId }})
                        {{ data.createdByName }}
                      </p>
                    </div>
                    <div class="col">
                      <p class="client-common">Feedback title</p>
                      <p class="count">{{ data.title }}</p>
                    </div>
                    <div class="col coloums">
                      <p class="client-common">Given feedback as</p>
                      <p class="count">
                        {{ data.feedbackRole | underScoreToSpace | titlecase }}
                      </p>
                    </div>
                    <div class="col coloums">
                      <p class="client-common">Score</p>
                      <p class="count">
                        {{ data.feedbackFrom[0].score | number : "1.2-2" }}/5
                      </p>
                    </div>
                    <div class="col coloums download">
                      <p class="btn-view" (click)="getQuestionsRatingOrphan(false, data.id)">
                        Download
                      </p>
                    </div>
                    <div class="col coloums download">
                      <p class="btn-view" (click)="viewRealTime(data)">View</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Probation starts @here -->
          <mat-tab class="mat-tab-label-active" onclick="changeToProbation('PROBATION')"
            *ngIf="this.probationTabVisible">
            <ng-template mat-tab-label> Probation Feedback </ng-template>
            <div class="mt-2 ProbationContent">
              <div class="no-data" *ngIf="probationFeedbackGroupdata.length == 0">
                No Probation Feedback Available
              </div>
              <mat-expansion-panel *ngIf="probationFeedbackGroupdata.length != 0"
                (opened)="open(probationFeedbackGroupdata)" (closed)="closeProbationDiv(probationFeedbackGroupdata)">
                <mat-expansion-panel-header style="padding: 0px 20px 0px 5px; height: 50px">
                  <mat-panel-title class="panel-title">
                    <div class="display-flex probation-end-date-lbl">
                      <div>
                        <div class="prob-upper">Probation</div>
                        <div class="prob-lower">Feedback</div>
                      </div>
                      <div>
                        Probation End date
                        {{ this.probationEndDate | date : "dd-MM-yy" }}
                      </div>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description class="center">
                    <button class="btn-white btn-modify" (click)="
                        EditProbationDialog(probationFeedbackGroupdata, true)
                      " *ngIf="!disableProbationfeedbackForm">
                      Modify
                    </button>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="calibratedScoreForm">
                  <table class="table-borderless mystyle1 table">
                    <thead>
                      <tr class="title">
                        <th class="emp-title">Employee</th>
                        <th class="role-title">Role</th>
                        <th class="status-title">Status</th>
                        <th class=""></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr formArrayName="array" *ngFor="
                          let feedback of this.opinion().controls;
                          let i = index
                        ">
                        <td [formGroupName]="i" data-label="Employee">
                          <label class="prob-username">(ID {{ feedback.value.feedbackFromId }})
                            {{ feedback.value.feedbackFrom | titlecase }}</label>
                        </td>
                        <td [formGroupName]="i" data-label="Role">
                          <label class="user_email">{{
                            feedback.value.feedbackRole
                            | underScoreToSpace
                            | titlecase
                            }}</label>
                        </td>
                        <td [formGroupName]="i" data-label="Status" *ngIf="
                            calibratedScoreForm.value.array[i]
                              .feedbackSubmissionStatus === 'READ' ||
                            calibratedScoreForm.value.array[i]
                              .feedbackSubmissionStatus === 'CREATED'
                          ">
                          <div *ngIf="
                              this.actualscorediv[i] &&
                              feedback.value.feedbackRole !=
                                'REVERSE_PROBATION_FEEDBACK'
                            ">
                            <input *ngIf="this.hideProbation == !true" class="user_email" matInput
                              formControlName="score" readonly />
                            <input *ngIf="this.hideProbation == true" class="hide user_email" matInput readonly
                              value="●●●" />
                          </div>
                          <div *ngIf="!this.actualscorediv[i]" class="display-flex">
                            Pending
                            <div>
                              <button class="remind" (click)="remindProbation(feedback.value.id, i)"
                                [disabled]="this.reminderStatus[i]">
                                <mat-icon class="notification-icon">
                                  circle_notifications</mat-icon>
                                Remind
                              </button>
                            </div>
                          </div>
                        </td>
                        <td [formGroupName]="i" data-label="Status" *ngIf="
                            calibratedScoreForm.value.array[i]
                              .feedbackSubmissionStatus == 'DECLINED'
                          ">
                          <div *ngIf="this.actualscorediv[i]">
                            <input class="hide" matInput formControlName="score" readonly />
                          </div>
                          <div *ngIf="!this.actualscorediv[i]" class="display-flex">
                            Declined
                            <div class="view" [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                              [matTooltip]="
                                calibratedScoreForm.value.array[i]
                                  .feedbackDeclinedReason
                              ">
                              <img src="assets/icons/view.png" />

                              View
                            </div>
                          </div>
                        </td>
                        <td class="mob-view-dots" *ngIf="
                            calibratedScoreForm.value.array[i]
                              .feedbackSubmissionStatus == 'SUBMITTED'
                          ">
                          <div class="dots-img display-flex" *ngIf="this.actualscorediv[i]">
                            Submitted
                            <ng-container *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackRole !==
                                  'REVERSE_PROBATION_FEEDBACK' || ifSuperAdmin;
                                else elseBlock
                              ">
                              <!-- Display three dots -->
                              <img src="assets/icons/3dots.png" [matMenuTriggerFor]="beforeMenu" class="threedots" />
                            </ng-container>

                            <ng-template #elseBlock>
                              <!-- Display the "Re-Submit" button -->
                              <button mat-menu-item class="re-btn" (click)="resubmitProbation(feedback.value.id)">
                                <span class="view">Re-Submit</span>
                              </button>
                            </ng-template>
                          </div>
                          <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">
                            <button mat-menu-item (click)="viewProbation(feedback)" *ngIf="
                                calibratedScoreForm.value.array[i]
                                  .feedbackSubmissionStatus === 'SUBMITTED'
                              ">
                              <div>
                                <label class="view">View</label>
                              </div>
                            </button>
                            <button mat-menu-item (click)="resubmitProbation(feedback.value.id)" *ngIf="this.ifHR">
                              <span class="view">Re-Submit</span>
                            </button>
                          </mat-menu>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </mat-expansion-panel>
            </div>
          </mat-tab>

          <!-- Summary starts @here -->
          <mat-tab class="mat-tab-label-active" *ngIf="this.disablefeedbackForm">
            <ng-template mat-tab-label class="tab-3"> Summary </ng-template>

            <div class="card-container1">
              <div class="matcardprojectdetail1" style="line-height: 1">
                <div class="section">
                  <img src="assets/icons/perf-summary.png" />
                  <div class="section-header">
                    Annual Performance Review Summary
                    <div class="perf-year">Year: {{ this.selected }}</div>
                  </div>
                </div>
                <div class="notes-data" [innerHTML]="feedbackClosureNotes"></div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>