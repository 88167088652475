<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h1 class="header_name">Assign Asset</h1>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
  <hr />
  <form [formGroup]="newAssetDetails">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Employee Name <span class="contentRed"></span></mat-label>
          <mat-select
            formControlName="employeeName"
            (selectionChange)="populateEmployeeId($event.value)"
          >
            <mat-option *ngFor="let empId of this.userlist" [value]="empId">
              {{ this.allEmployeeIds[empId] }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('employeeName', 'required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Employee ID<span class="contentRed"></span></mat-label>
          <input
            matInput
            formControlName="employeeId"
            autocomplete="off"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Date of Asset Assignment </mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [min]="minAssignDate"
            placeholder="dd/mm/yyyy"
            formControlName="assetAssignedDate"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="checkError('assetAssignedDate', 'required')"
            >Required</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="row">
    <div class="col-md-12">
      <div class="submit_button_main">
        <button
          class="add_Emp_btn"
          *ngIf="!this.assignInProgress"
          (click)="onSubmit()"
        >
          <span class="add_em_name">Assign</span>
        </button>
        <button class="add_Emp_btn" *ngIf="this.assignInProgress" disabled>
          <span class="add_em_name">Assigning...</span>
        </button>
      </div>
    </div>
  </div>
</div>
