import { Component, OnInit } from '@angular/core';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AttendanceStatus } from '../../../services/attendance-extra/attendance-enum';
import { Attendance } from 'src/app/models/attendance';

@Component({
  selector: 'app-daily-attendance',
  templateUrl: './daily-attendance.component.html',
  styleUrls: ['./daily-attendance.component.scss'],
})
export class DailyAttendanceComponent implements OnInit {
  currentDate: string;
  mark_Attendance :boolean;
  attendance_Marked :boolean;
  disableState: boolean = false;
  attendanceStatus: boolean = false;
  data: Attendance = new Attendance();

  constructor(
    private attendanceService: AttendanceService,
    public userDetatilsService: UserDetailsService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.currentDate = this.getCurrentDate();
    this.checkAttendanceStatus();
  }

  getCurrentDate(): string {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  }

  checkAttendanceStatus() {
    this.attendanceService.getAttendanceForLoggerInUser().subscribe((res) => {
      if (res.body == false) {
        this.mark_Attendance = true;
        this.attendance_Marked = false;
      } else {
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.disableState = false;
      }
    });
  }

  markAttendance() {
    this.checkAttendanceStatus();
    if (!this.disableState) {
      this.data.createPayload(
        new Date(),
        AttendanceStatus.PRESENT,
        this.userDetatilsService.getUserDetails().id
      );
      this.attendanceService.SendAttendance(this.data).subscribe((res) => {
        this.disableState = true;
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.notificationService.sucess(
          'Your Attendance is marked Successfully'
        );
      });
    }
    this.disableState = true;
  }
}
