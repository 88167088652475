<mat-card class="main-card ">
  <div class="row">
    <div class="col-md-12">
      <div class="main-header">
        <div class="svg-heading-div">
          <img class="submodule-svg" src="assets/icons/dashboard-icons/calendar.svg" />
          <div class="head">Leave Balance</div>
        </div>
        <div class="date-color">{{ selectedMonth }}-{{ selectedYear }}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <!--  leave balance with empty data -->
      <div class="tableFixHead-empty" *ngIf="!leavebalanceData || leavebalanceData.length == 0">
        <div class="headingOne">
          <div class="no-data-image">
            <img src="assets/icons/dashboard-icons/white-calendar-icon.svg" />
          </div>
          <div class="no-data-text">No Leave Balance.</div>
        </div>
      </div>

      <!--  leave balance with data -->
      <div class="tableFixHead" *ngIf="leavebalanceData && leavebalanceData.length != 0">

        <div *ngIf="this.restrictedHolidaysLeaves" class="single-balance-container">
          <div class="svg-container floater-bg">
            <img src="assets/icons/dashboard-icons/yellow-calendar.svg" />
          </div>
          <div class="leave-type">
            Floater Leave
            <span class="floater-warning">{{floaterMessage}}</span>
          </div>
          <div class="leave-balance">
            <div class="num yellow-color">
              {{ this.restrictedHolidaysLeaves | number : "2.0-0" }}
            </div>
            <img class="redirect-svg" (click)="redirectToLeaveAvail()"
              src="assets/icons/dashboard-icons/navigation-icon.svg" />
          </div>
        </div>

        <div *ngIf="this.unplannedLeave" class="single-balance-container">
          <div class="svg-container unplanned-bg">
            <img src="assets/icons/dashboard-icons/pink-calendar.svg" />
          </div>
          <div class="leave-type">Unplanned Leave</div>
          <div class="leave-balance">
            <div class="num pink-color">
              {{ this.unplannedLeave | number : "2.0-0" }}
            </div>
            <img class="redirect-svg" (click)="redirectToLeaveApply('UNPLANNED_LEAVE')"
              src="assets/icons/dashboard-icons/navigation-icon.svg" />
          </div>
        </div>

        <div *ngIf="this.paidLeave" class="single-balance-container">
          <div class="svg-container planned-bg">
            <img src="assets/icons/dashboard-icons/green-calendar.svg" />
          </div>
          <div class="leave-type">Planned Leave</div>
          <div class="leave-balance">
            <div class="num green-color">
              {{ this.paidLeave | number : "2.0-0" }}
            </div>
            <img class="redirect-svg" (click)="redirectToLeaveApply('PLANNED_LEAVE')"
              src="assets/icons/dashboard-icons/navigation-icon.svg" />
          </div>

        </div>

      </div>
    </div>
  </div>

</mat-card>