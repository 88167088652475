import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApplyWfoComponent } from './apply-wfo/apply-wfo.component';
import { WfoService } from 'src/app/services/work-from-office/wfo.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { RefreshService } from 'src/app/services/refreshService/refresh.service';
import { WfoDetailComponent } from './wfo-detail/wfo-detail.component';
import { UserDTO } from 'src/app/dtos/UserDTO';

@Component({
  selector: 'app-working-from-office',
  templateUrl: './working-from-office.component.html',
  styleUrls: ['./working-from-office.component.scss'],
})
export class WorkingFromOfficeComponent implements OnInit {
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years: number[] = [];
  date: Date = new Date();
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  AllHistoryOfDirectreporteeList: UserDTO[] = [];
  userId: string;
  LastApprovedDetailsOfUser: any[] = [];

  constructor(
    private dialog: MatDialog,
    private wfoService: WfoService,
    private userDetailService: UserDetailsService,
    private refreshService: RefreshService
  ) {}

  ngOnInit(): void {
    this.refreshComponent();
    this.initiateComponent();
  }

  refreshComponent() {
    this.refreshService.refresh$.subscribe((componentName) => {
      if (componentName === 'working-from-office') {
        this.initiateComponent();
      }
    });
  }

  initiateComponent() {
    this.userId = this.userDetailService.getUserDetails().id;

    this.wfoService
      .getAllHistoryDirectReportee(this.userId)
      .subscribe((res) => {
        this.AllHistoryOfDirectreporteeList = [];
        for (let r of res.body) {
          const dateParts = r.lastApprovedDate.split('-');
          if (dateParts.length === 3) {
            const lastApprovedMonth = new Date(
              +dateParts[2],
              +dateParts[1] - 1,
              +dateParts[0]
            );
            const lastApprovedMonthString =
              this.months[lastApprovedMonth.getMonth()];
            if (this.selectedMonth === lastApprovedMonthString) {
              this.AllHistoryOfDirectreporteeList.push(r);
            }
          } else {
          }
        }
      });

    this.wfoService.getLastAprrovedUserDetails(this.userId).subscribe((res) => {
      this.LastApprovedDetailsOfUser = res.body;
    });
  }

  applyWFO() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '24%';
    dialogConfig.height = '50%';
    const dialogRef = this.dialog.open(ApplyWfoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (result) => {
        this.ngOnInit();
      },
      () => {}
    );
  }

  openWfoDetail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70%';
    dialogConfig.height = '80%';
    const dialogRef = this.dialog.open(WfoDetailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
