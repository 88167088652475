<div class="row no-goal-div col-lg-12">
  <div class="col-md-12 mt-3 no-padding" *ngIf="!this.yearcheck">
    <div class="performance_status_year col-lg-2">
      <div class="performance-status-lbl">
        {{ selected }}
      </div>
      <div>
        <mat-select
          class="year"
          [(value)]="selected"
          (selectionChange)="changeYear($event)"
        >
          <mat-option *ngFor="let year of years" [value]="year">{{
            year
          }}</mat-option>
        </mat-select>
      </div>
    </div>
    <div class="mt-2"></div>
  </div>
  <div class="no-goal" *ngIf="this.goalCount == 0">
    <img class="no-goal-img" src="\assets\images\no-goal.png" alt="" />
    <div class="no-goal-assign" *ngIf="!this.useridcheck">
      No goal is assigned to you..
    </div>
    <div class="no-goal-assign" *ngIf="this.useridcheck">
      <button
        class="assign-goal-btn"
        mat-raised-button
        (click)="openAssignGoalDialogue()"
      >
        <span>Assign a Goal</span>
      </button>
    </div>
  </div>
  <div class="main-container row" *ngIf="this.goalCount > 0">
    <div class="goal-status-chart">
      <div class="main-card first">
        <mat-card class="sub-card status-card" *ngIf="this.subgoalcount > 0">
          <div class="display-flex full-width">
            <div class="status-lbl">
              <span>Status Report</span>
            </div>
            <div class="performance-year-lbl">
              <span>Performance - {{ selected }}</span>
            </div>
          </div>

          <div class="display-flex pie-chart-card">
            <div class="pie-chart">
              <ngx-charts-pie-chart
                [view]="view"
                [results]="[
                  {
                    name: 'At Risk',
                    value: (this.tempGoalStatus[2] / this.subgoalcount) * 100
                  },
                  {
                    name: 'Need Attention',
                    value: (this.tempGoalStatus[3] / this.subgoalcount) * 100
                  },
                  {
                    name: 'Not Started',
                    value: (this.tempGoalStatus[1] / this.subgoalcount) * 100
                  },
                  {
                    name: 'On Track',
                    value: (this.tempGoalStatus[0] / this.subgoalcount) * 100
                  },
                  {
                    name: 'Closed',
                    value: (this.tempGoalStatus[4] / this.subgoalcount) * 100
                  }
                ]"
                [doughnut]="true"
                [scheme]="colorScheme"
              >
              </ngx-charts-pie-chart>
            </div>
            <div>
              <label class="performance_year">{{ subgoalcount }}</label>
            </div>
            <div class="bar-div goal-status">
              <div class="status-progress">
                <div class="goal-status-div">
                  <div class="display-flex goal-status-lbl">
                    <div class="goal-status-box risk"></div>
                    <div>At Risk</div>
                  </div>
                  <div>
                    {{
                      (this.tempGoalStatus[2] / this.subgoalcount) * 100
                        | number : "1.0-0"
                    }}%
                  </div>
                </div>
                <div class="goal-status-div">
                  <div class="display-flex goal-status-lbl">
                    <div class="goal-status-box need-attention"></div>
                    <div>Need Attention</div>
                  </div>
                  <div>
                    {{
                      (this.tempGoalStatus[3] / this.subgoalcount) * 100
                        | number : "1.0-0"
                    }}%
                  </div>
                </div>
                <div class="goal-status-div">
                  <div class="display-flex goal-status-lbl">
                    <div class="goal-status-box not-started"></div>
                    <div>Not Started</div>
                  </div>
                  <div>
                    {{
                      (this.tempGoalStatus[1] / this.subgoalcount) * 100
                        | number : "1.0-0"
                    }}%
                  </div>
                </div>
                <div class="goal-status-div">
                  <div class="display-flex goal-status-lbl">
                    <div class="goal-status-box on-track"></div>
                    <div>On Track</div>
                  </div>
                  <div>
                    {{
                      (this.tempGoalStatus[0] / this.subgoalcount) * 100
                        | number : "1.0-0"
                    }}%
                  </div>
                </div>
                <div class="goal-status-div">
                  <div class="display-flex goal-status-lbl">
                    <div class="goal-status-box closed"></div>
                    <div>Closed</div>
                  </div>
                  <div>
                    {{
                      (this.tempGoalStatus[4] / this.subgoalcount) * 100
                        | number : "1.0-0"
                    }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <mat-card
          *ngIf="this.subgoalcount == 0"
          class="sub-card sub-goal status-card"
        >
          <p class="no-subgoal-lbl">
            Think about the results you want to see. Start creating your sub
            goals.
          </p>
        </mat-card>
      </div>
    </div>
    <div
      *ngFor="let g of this.goal; let i = index; let even = even"
      class="main-goal-card"
    >
      <ng-container *ngIf="even">
        <ng-container *ngIf="this.goal[i]">
          <mat-card class="goal-card row">
            <div class="display-flex user-name">
              <div class="full-width">
                {{ userIdName[this.goal[i].userId] | titlecase }}
              </div>
              <div class="right-align full-width">
                {{ this.averageGoal[this.goal[i].id] }}/100%
              </div>
            </div>
            <div class="display-flex goal-type-div mt-2">
              <div class="goal-type full-width">
                {{ goalTypeMapping[this.goal[i].goalType] }}
              </div>
              <div class="right-align full-width">
                <button
                  class="modify-btn"
                  (click)="managesubgoals(this.goal[i])"
                  *ngIf="this.currentYear == selected"
                >
                  <span>Modify</span>
                </button>
                <button
                  class="modify-btn"
                  (click)="viewSubgoals(this.goal[i])"
                  *ngIf="this.currentYear != selected"
                >
                  <span>View</span>
                </button>
              </div>
            </div>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="this.goal[i + 1]">
          <mat-card class="goal-card row">
            <div class="display-flex user-name">
              <div class="full-width">
                <span>{{
                  userIdName[this.goal[i + 1].userId] | titlecase
                }}</span>
              </div>
              <div class="right-align full-width">
                {{ this.averageGoal[this.goal[i + 1].id] }}/100%
              </div>
            </div>
            <div class="display-flex mt-2">
              <div class="goal-type full-width">
                <span>{{ goalTypeMapping[this.goal[i + 1].goalType] }}</span>
              </div>
              <div class="right-align full-width">
                <button
                  class="modify-btn"
                  (click)="managesubgoals(this.goal[i + 1])"
                  *ngIf="this.currentYear == selected"
                >
                  <span>Modify</span>
                </button>
                <button
                  class="modify-btn"
                  (click)="viewSubgoals(this.goal[i])"
                  *ngIf="this.currentYear != selected"
                >
                  <span>View</span>
                </button>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
